.documentInfoContainer{
    display: flex !important;
    justify-content: space-between !important;
    width: 45% !important;
    margin-left: 157px !important;
}

.documentInfoItem{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin:  0 10px 5px 10px;

    :first-child{
	color: #669900 !important;
    margin-bottom: 3px;
    }
}

