h3 {
	color: #669900;
	text-transform: capitalize;
}
.stepsNav {
	justify-content: start;
	// padding-top: 2rem;
	// padding-bottom: 2rem;
	margin-left: auto;
	margin-right: auto;
}

.navLink {
	position: relative;
	color: #999;
	font-size: 0.8rem;
	
	&:hover {
		color: #999;
	}

	&:after {
		content: "";
		display: block;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background: #ccc;
		position: absolute;
		top: 50%;
	}

	&:before {
		content: "";
		display: block;
		width: 2px;
		height: calc(100% + 1rem);
		background: #ccc;
		position: absolute;
		top: -0.5rem;
	}
}

.processingNavLink {
	// background-color: #ffa000 !important;
	color: #999;
	// border-radius: 0.75rem !important;
	// &::after {
	// 	background-color: #ffa000;
	// 	box-shadow: #ffa000 0px 0px 0px 5px;
	// 	border: 3px solid #fff;
	// }

	&:hover {
		color: #999;
	}
}

.processingNavLinkDone {
	padding: 0.5rem 1.5rem !important;
	&::after {
		background: url(../../../images/done.svg) #669900 !important;
		background-size: 15px 15px !important;
		width: 15px !important;
		height: 15px !important;
		box-shadow: #669900 0px 0px 0px 5px;
		border: none !important;
	}
}

.processingNavLinkTodo {
	padding: 0.5rem 1.5rem !important;
	&::after {
		background: url(../../../images/require.svg) #669900 !important;
		background-size: 15px 15px !important;
		width: 15px !important;
		height: 15px !important;
		box-shadow: #669900 0px 0px 0px 5px;
		border: none !important;
	}
}

.navLink.active {
	background-color: #669900 !important;
	color: #fff !important;
	font-size: 1rem;

	&:after {
		width: 15px;
		height: 15px;
		background: #669900;
		border: 3px solid #fff !important;
		box-shadow: #669900 0px 0px 0px 5px;
		opacity: 1;
		right: -7px;
	}

	&:before {
		background: #669900;
		opacity: 1;
	}
}
//
.actionsWrap {
	position: fixed;
	top: 60px;
	z-index: 3;
	background-color: #fafafa;
	border-top-right-radius: 10px;
	padding: 0.9rem;
	padding-top: 1.6rem;
	padding-left: 18%;
	width: 100%;
	left: 0;
	height: 6rem;
	box-shadow: 10px 0px 10px rgba(0, 0, 0, 0.1);
	& a,
	button {
		margin: 0.25rem;
	}
}
.tasksWrap,
.altWrap {
	background-color: #fafafa;
	border-radius: 20px;
	padding: 12px;
	margin-top: 20px;
}

.tasksWrap {
	padding-bottom: 30px;
}

.taskWrap {
	transition: all 0.4s ease-in-out;
	&:hover {
		box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.3);
		border-radius: 8px;
		margin-left: -7px;
		margin-right: -7px;
	}
}

.stepWrap {
	label {
		font-size: 11px;
		color: #669900;
		&.labelCentered {
			font-weight: 600;
			font-size: 20px;
			margin-top: 35px;
			margin-bottom: 20px;
			text-align: center;
			width: 100%;
			display: block;
		}
		& ~ p {
			border-bottom: 1px dotted rgba($color: #000000, $alpha: 0.42);
			padding-bottom: 7px;
			cursor: default;
			white-space: break-spaces;
		}
	}
}

.accordionWrap {
	border-radius: 24px !important;
	&:last-child {
		border-radius: 25px;
	}
	&:before {
		display: none;
	}
}

.accordionDetail {
	display: block !important;
}

.processingLoader {
	position: absolute;
	z-index: 10;
	background-color: rgba(#fff, 0.8);
	width: 100%;
	height: 100%;
	min-height: 400px;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 20px;
	left: 0;
	right: 0;
	bottom: 0;
	img {
		max-width: 70px;
		height: auto;
	}
}

.labelActionAr,
.labelActionEn {
	z-index: 2;
	position: absolute;
	top: -7px;
	color: #d32f2f;
	text-decoration: none;

	&:hover {
		color: #b71c1c;
		text-decoration: none;
	}
}

.labelActionEn {
	right: 15px;
}

.labelActionAr {
	left: 15px;
}

.dashboardListContainer{
	// overflow-y: overlay;
	overflow-x: hidden;
	z-index: 9;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	transform: translate3d(0,0,0);

}

.advisorNumberCheckColumn{
	display: flex;
	align-items: baseline;
	gap: 5px;
}