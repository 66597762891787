@include loginTheme(
    // $theme-name
    bonobo,
    // $primary
    #001551,
    // $secondary
    #ffcc00,
    // $info
    #001551,
    // $bgOverlay
    url('./../../images/login/background/BonoboBGOverlay.png'),
    // $bg
    url('./../../images/login/background/BonoboBG.png')
);

@include loginTheme(
    // $theme-name
    prov, 
    // $primary
    #b1001b, 
    // $secondary
    #b1001b,
    // $info
    #262626,
    // $bgOverlay
    url('./../../images/login/background/ProVBGOverlay.png'),
    // $bg
    url('./../../images/login/background/ProVBG.png')
);

@include loginTheme(
    // $theme-name
    egs, 
    // $primary
    #0099cc, 
    // $secondary
    #0099cc,
    // $info
    #0099cc,
    // $bgOverlay
    url('./../../images/login/background/EGSBGOverlay.png'),
    // $bg
    url('./../../images/login/background/EGSBG.png')
);

@include loginTheme(
// $theme-name
sharjah,
// $primary
#151713,
// $secondary
#4e6b31,
// $info
#4e6b31,
// $bgOverlay
url('./../../images/login/background/SH-Login-BG.png'),
// $bg
url('./../../images/login/background/SHJLoginBG.jpg') );