.infoWrap {
    padding-top: 20px;
    padding-bottom: 20px;
    label {
        font-size: 11px;
        color: #669900;
    &.labelCentered {
        font-weight: 600;
        font-size: 20px;
        margin-top: 35px;
        margin-bottom: 20px;
        text-align: center;
        width: 100%;
        display: block;
    }
       & ~ p {
            border-bottom: 1px dotted rgba($color: #000000, $alpha: 0.42);
            padding-bottom: 7px;
            cursor: default;
        }
    }
}

.radioLabel {
    margin-top: 14px;
    font-size: .8rem !important;
    label {
        color: #333 !important;
    }
}

.labelAlt {
    background-color: #f4f4f4;
    padding: 8px 16px;
    border-radius: 8px;
    font-weight: 600;
}
