.profileImg {
    object-fit: cover;
    border-radius: 50%;
    width: 35px; 
    height: 35px;
    border: 1px solid #ccc;
}

.userName {
    font-weight: 600;
    font-size: 13px;
    margin: 0 5px;
    text-transform: capitalize;
}

.departmentName {
    display: block;
    font-size: 11px;
    margin: 0 5px;
    text-transform: capitalize;
}

.dropdownBtn {
    background: transparent;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dropdown {
    .menuItem {
        padding: .5rem 2rem!important;
        min-width: 300px!important;
    }
    .subTitle{
        width: 100%;
        height: 100%;
        background-color: #d0b166;
        color:#fff;
        &:hover{
            background-color: #d0b166;
        }
        & h6{
            margin-bottom: 0;
        }
    }
    .dropdownItem {
        padding: .5rem 2rem!important;
        min-width: 300px!important;
        justify-content: space-between;
    }
    .selectedItem {
        color: #669900;
        font-weight: 700;
        background-color: #f0f0f0;
        & svg{
            background-color: #669900;
            color: #fff;
            margin: 0 5px;
            border-radius: 50%;
            padding: 5px;
        }
    }
    .AccountItem{
        padding: .75rem 1.5rem!important;
    }
    .profileGroup{
        color:#999;
    }
}

@mixin appTheme($theme-name, $primary, $secondary, $text-primary, $text-secondary, $default-svg) {
    :global(.#{$theme-name}) {
        .userDropdown {
            background-color: $primary !important;
            color: $text-secondary !important;
            margin-top: -8px !important;
            // margin-bottom: 1rem !important;
            height: 80px;
        }

        .userInfo {
            display: flex;
            flex-direction: column;

            &.userInfoAR {
                align-items: flex-end;
            }

            &.userInfoEN {
                align-items: flex-start;
            }

            .dropdownUsername, .dropdownDepartmentName {
                font-weight: 600;
                font-size: 15px;
                margin: 0 15px;
                text-transform: capitalize;
            }

            .dropdownDepartmentName {
                font-size: 13px;
            }
            
        }

        .userName, .departmentName {
            color: lighten($text-primary, 30%);

            @media (max-width: 959px) {
                color: $primary;
            }
        }
    }
}

@import './../../../../../global/style/app_themes.scss'