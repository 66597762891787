@mixin appTheme($theme-name, $primary, $secondary, $text-primary, $text-secondary, $default-svg) {
    :global(.#{$theme-name}) {

        
        .dashboardOverlay {

            &::before {
                content: ' ';
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background: url(./../../images/login/background/SH-BG.png);
                position: fixed;
                background-repeat: round;
            }

            .title {
                color: black;
            }
            
        }

        .title {
            color: black;
        }

        .activeCard {
            border-radius: 4px;
            padding: 0.5rem 1rem;
            height: 100%;
            max-height: 250px;
            margin-bottom: 1rem;

            .imageCont {
                background: #fff;
                padding: 8px;
                border-radius: 5px;
                align-self: flex-start;

                > img {
                    height: 90px;
                }
            }

            .cardTitle {
                color: #fff;
                font-size: 1.3rem;
                font-weight: bold;
            }

            .cardStat {
                color: #fff;
                font-size: 4rem;
                font-weight: bold;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: baseline;

                .activeCardRefresh {
                    svg {
                        width: 4rem;
                        height: 4rem;
                        display: block;
                        margin-bottom: 5px
                    }
                }

                .activeCardRefresh path {
                    fill: #fff;
                }
            }

          
        }

        .orangeBackground{
            background: #d0b166;
            }

        .blackBackground{
            background: black;
            }

        .cards {
            background: #fff;
            border-bottom: #d0b166 0.6rem solid;
            border-radius: 4px;
            padding: 0.5rem 1rem;
            height: 120px;

            &.disabledCard {
                opacity: 0.2;
                cursor: default;
            }

            &.enabledCard {
                opacity: 1;
                cursor: pointer;
            }

            .cardTitle {
                color: #33bb00;
                font-size: 1rem;
                font-weight: bold;
            }

            .cardStat {
                color: #33bb00;
                font-size: 2.5rem;
                font-weight: bold;
            }
        }

        .date {
            border-radius: 50px!important;
        }

        .dateLabel {
            color: black;
            font-size: 1rem!important;
        }

        .formLabel {
            color: #fff;
            font-size: 1rem!important;
        }

        .searchResultsLabel {
            color: black;
            font-size: 1rem;
            text-align: center;
            font-weight: bold;
            margin-bottom: 0;
        }

        .searchResults {
            color: black;
            font-size: 1.5rem;
            text-align: center;
            font-weight: bold;
        }

        .dashboardForm {
            :global(.MuiFormLabel-root) {
                color: #333!important;
            }

            :global(.MuiFormLabel-root.Mui-focused) {
                color: #fff!important;
                font-size: 1rem!important;
            }

            :global(.MuiInput-underline:before) {
                background: #fff!important;
            }

            
        }

        .tooltip {
            :global(.dx-popup-content) {
                color: #a58b4d!important;
                font-weight: bold;
                font-size: 1rem!important;
            }
        }


        .refreshIcon {
            margin: 0 10px 5px 10px;
            gap: 10px;
            display: flex;
            color: black;
            font-weight: bold;
            font-size: 1.7rem;
            align-items: center;
        }

        .refreshIcon > svg {
            width: 1.5rem;
            height: 1.5rem;
            cursor: pointer;
        }

        .refreshIcon path {
            fill:black;
        }

        .lastUpdate {
            color: black;
            margin-bottom: 0;
            font-weight: bold;
            font-size: 1.1rem;
        }

        .logoContainer{
            display: flex;
            width: 100%;
            justify-content: center;
            padding-top: 2rem;
        }


        .dashboardListContainer{
            // overflow-y: overlay;
            overflow-x: hidden;
            z-index: 9;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            transform: translate3d(0,0,0);

    }
                   
                }
                .dataGridTitle{
                    z-index: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;

                   & h3{
                 color: black !important;

                    }
                }

}




@import './../../global/style/app_themes.scss';


